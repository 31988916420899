/* eslint-disable vue/valid-v-bind */
<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">
      Agregar {{ !isAdvancedPayment ? "pago" : "anticipo" }}
    </h1>

    <b-checkbox
      v-model="newPayment.requireCDFI"
      v-if="
        !objectInformation &&
        accountType === 'CC' &&
        type === 'Venta' &&
        checkIfCanInvoiceCompany() &&
        income &&
        ((income.CLAVE_VENTA &&
          income.CLAVE_VENTA.STATUS_FAC === 'TI' &&
          income.CLAVE_VENTA.METODO_P === 'D') ||
          (income.CLAVE_VENTA_SERVICIO &&
            income.CLAVE_VENTA_SERVICIO.STATUS_FAC === 'TI' &&
            income.CLAVE_VENTA_SERVICIO.METODO_P === 'D'))
      "
      class="special-margin"
    >
      Timbrar pago
    </b-checkbox>

    <b-field grouped class="special-margin">
      <b-field
        label="Restante a pagar (Requerido)"
        expanded
        label-position="inside"
        v-if="!objectInformation"
      >
        <MoneyInput
          currency="MXN"
          :digits="5"
          placeholder="Ejemplo: 16"
          icon="cash-100"
          v-model="totalQuantity"
          :required="true"
          :disabled="true"
        >
        </MoneyInput>
      </b-field>
    </b-field>

    <div>
      <h3 class="form-division">Información de cuenta</h3>
      <b-field grouped class="special-margin">
        <b-field
          label="Cuenta bancaria (Requerido)"
          expanded
          label-position="inside"
          required
          class="required-input"
          :type="{ 'is-danger': newPaymentErrors.CUENTA }"
          :message="{
            'La cuenta bancaria del pago no es valida': newPaymentErrors.CUENTA,
          }"
        >
          <b-select
            placeholder="Ejemplo: Cuenta de gastos"
            expanded
            icon="credit-card-outline"
            v-model="newPayment.CUENTA"
            @change.native="setAccountInformation()"
          >
            <option value="" disabled>Sin seleccionar</option>
            <option
              :value="singleAccount._id"
              v-for="singleAccount in bankAccounts"
              v-bind:key="singleAccount._id"
            >
              {{
                singleAccount.NOMBRE
                  ? singleAccount.NOMBRE
                  : singleAccount.CUENTA
              }}
            </option>
          </b-select>
        </b-field>
      </b-field>

      <b-progress
        v-if="newPayment.CUENTA && accountType === 'CP'"
        :rounded="false"
        type="is-success"
        size="is-medium"
        :value="selectedAccountBalance"
        :max="selectedAccountBalance"
        show-value
        format="raw"
        :precision="2"
        :keep-trailing-zeroes="true"
        locale="es-MX"
      >
        {{ selectedAccountBalance | money("MXN", 2) }}
      </b-progress>

      <b-field grouped class="special-margin invoice-article-div">
        <b-field
          :label="
            companyInformation.MOVIMIENTO_OBLIGATORIO && !CLIENTE_MERCADO_LIBRE
              ? 'Movimiento bancario a usar (Requerido)'
              : 'Movimiento bancario a usar (Opcional)'
          "
          expanded
          label-position="inside"
          :class="{
            'required-input':
              companyInformation.MOVIMIENTO_OBLIGATORIO &&
              !CLIENTE_MERCADO_LIBRE,
          }"
          :type="{
            'is-danger':
              newPaymentErrors.CLAVE_MOVIMIENTO &&
              companyInformation.MOVIMIENTO_OBLIGATORIO &&
              !CLIENTE_MERCADO_LIBRE,
          }"
          :message="{
            'El movimiento bancario del pago no es válido':
              newPaymentErrors.CLAVE_MOVIMIENTO &&
              companyInformation.MOVIMIENTO_OBLIGATORIO &&
              !CLIENTE_MERCADO_LIBRE,
          }"
        >
          <b-autocomplete
            placeholder="Movimiento bancario"
            :data="autoCompleteMovement(movementName)"
            v-model="movementName"
            field="MOVIMIENTO_NOMBRE_FOR"
            autocomplete
            icon="credit-card-outline"
            open-on-focus
            expanded
            clearable
            @select="
              (option) => {
                if (option) {
                  newPayment.CLAVE_MOVIMIENTO = option._id;
                  handleMovementSelected();
                } else {
                  newPayment.CLAVE_MOVIMIENTO = undefined;
                  selectedAccountMovementBalance = 0;
                }
              }
            "
            check-infinite-scroll
          >
            <template #empty>No se encontraron movimientos</template>
          </b-autocomplete>
        </b-field>

        <b-button
          icon-right="magnify"
          size="is-small"
          type="is-primary"
          class="search-payment"
          @click="openSearchMovement()"
          v-if="newPayment.CUENTA && !objectInformation"
        >
        </b-button>
      </b-field>

      <b-field
        label="Disponible a usar en movimiento (Opcional)"
        expanded
        label-position="inside"
        v-if="newPayment.CLAVE_MOVIMIENTO && !objectInformation"
        class="special-margin"
      >
        <MoneyInput
          currency="MXN"
          :digits="5"
          placeholder="Ejemplo: 16"
          icon="cash-100"
          v-model="selectedAccountMovementBalance"
          :required="true"
          :disabled="true"
        >
        </MoneyInput>
      </b-field>
    </div>

    <h3 class="form-division">Información general</h3>

    <b-field grouped class="special-margin">
      <b-field
        label="Monto (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': newPaymentErrors.PAGO_PARC }"
        :message="{
          'El monto del pago no es valido': newPaymentErrors.PAGO_PARC,
        }"
      >
        <MoneyInput
          currency="MXN"
          :digits="5"
          placeholder="Ejemplo: 16"
          icon="cash-100"
          v-model="newPayment.PAGO_PARC"
          :required="true"
          :disabled="false"
        >
        </MoneyInput>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Forma de pago (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': newPaymentErrors.FORMA_P }"
        :message="{
          'El tipo del pago no es valido': newPaymentErrors.FORMA_P,
        }"
      >
        <b-select
          placeholder="Ejemplo: Transferencia"
          expanded
          icon="credit-card-plus-outline"
          class="required-input"
          required
          v-model="newPayment.FORMA_P"
        >
          <option value="" disabled>Selecciones una forma de pago</option>
          <option
            :value="singlePaymentType._id"
            v-for="singlePaymentType in paymentTypes"
            v-bind:key="singlePaymentType._id"
          >
            {{ singlePaymentType.DESCRIBE_P }}
          </option>
        </b-select>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Número de cuenta (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: Martillo azul con mango de madera"
          expanded
          icon="card-account-details-outline"
          v-model="newPayment.NO_CUENTA"
        ></b-input>
      </b-field>
      <b-field
        label="CLABE interbancaria (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 000000000000000000"
          expanded
          icon="bank-outline"
          v-model="newPayment.NO_CLABE"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Número de tarjeta (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 0000000000000000"
          expanded
          icon="card-text-outline"
          v-model="newPayment.NO_TARJETA"
        ></b-input>
      </b-field>
      <b-field
        label="Número de cheque (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 12398"
          expanded
          icon="card-bulleted-outline"
          v-model="newPayment.NO_CHEQUE"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Fecha de pago (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': newPaymentErrors.FECHA_PAGO }"
        :message="{
          'La fecha del pago no es valida': newPaymentErrors.FECHA_PAGO,
        }"
        class="required-input"
      >
        <b-datetimepicker
          :datetime-formatter="
            (date) => moment(date).format('DD/MM/YYYY HH:mm:ss')
          "
          placeholder="Fecha de pago"
          icon="calendar-today"
          required
          v-model="newPayment.FECHA_PAGO"
          trap-focus
          :locale="locale"
          :datepicker="{ dayNames, monthNames, locale }"
          :timepicker="{ enableSeconds, hourFormat }"
          horizontal-time-picker
        >
        </b-datetimepicker>
      </b-field>
      <b-field
        label="Referencia (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': newPaymentErrors.REFERENCIA }"
        :message="{
          'La referencia del pago no es valida': newPaymentErrors.REFERENCIA,
        }"
      >
        <b-input
          placeholder="Ejemplo: 12387BH"
          expanded
          icon="pencil-box"
          required
          v-model="newPayment.REFERENCIA"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Observaciones (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: Pago realizado el día Martes"
          expanded
          v-model="newPayment.OBSERVACIONES"
          type="textarea"
        ></b-input>
      </b-field>
    </b-field>

    <!--
    <h3
      class="form-division"
      v-if="!objectInformation && !newPayment.CLAVE_MOVIMIENTO"
    >
      Ajustes en cuenta bancaria
    </h3>

    <b-checkbox
      v-model="newPayment.createMovement"
      class="permissions-checkbox first-row-checkbox"
      v-if="!objectInformation && !newPayment.CLAVE_MOVIMIENTO"
    >
      Crear movimiento en cuenta bancaria
    </b-checkbox>

    <b-checkbox
      v-model="newPayment.affectBalance"
      class="permissions-checkbox first-row-checkbox"
      v-if="!objectInformation && !newPayment.CLAVE_MOVIMIENTO"
    >
      Afectar saldo de cuenta bancaria
    </b-checkbox>
    -->

    <div class="add-global-controls">
      <b-tooltip
        v-if="
          (!newPayment.CUENTA ||
            newPayment.PAGO_PARC > selectedAccountBalance) &&
          !objectInformation &&
          accountType === 'CP'
        "
        label="No cuentas con saldo consolidado suficiente para registar este pago o aun no seleccionas una cuenta bancaria"
        multilined
      >
        <b-button type="is-success" disabled>Registrar pago</b-button>
      </b-tooltip>

      <b-button
        type="is-success"
        @click="addNewPayment()"
        v-if="
          newPayment.CUENTA &&
          newPayment.PAGO_PARC <= selectedAccountBalance &&
          !objectInformation &&
          accountType === 'CP'
        "
        >Registrar pago</b-button
      >

      <b-button
        type="is-success"
        @click="addNewPayment()"
        v-if="!objectInformation && accountType === 'CC'"
        >Registrar pago</b-button
      >

      <b-button
        type="is-success"
        v-if="
          objectInformation &&
          objectInformation.STATUS !== 'CA' &&
          (objectInformation.STATUS_FAC === 'SF' ||
            objectInformation.STATUS_FAC === 'PF' ||
            objectInformation.STATUS_FAC === 'PP') &&
          !noInvoice &&
          type === 'Venta' &&
          ((income.CLAVE_VENTA && income.CLAVE_VENTA.METODO_P === 'D') ||
            (income.CLAVE_VENTA_SERVICIO &&
              income.CLAVE_VENTA_SERVICIO.METODO_P === 'D'))
        "
        @click="savePaymentInvoice()"
        >Timbrar pago</b-button
      >

      <b-button
        type="is-danger"
        @click="closeModal()"
        :class="{
          'cancel-button':
            !noInvoice &&
            income &&
            ((income.CLAVE_VENTA && income.CLAVE_VENTA.METODO_P !== 'P') ||
              (income.CLAVE_VENTA_SERVICIO &&
                income.CLAVE_VENTA_SERVICIO.METODO_P !== 'P')) &&
            (!objectInformation ||
              objectInformation.STATUS !== 'CA' ||
              (objectInformation.STATUS_FAC === '' && type === 'Venta')),
        }"
        >Regresar</b-button
      >
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { EventBus } from "@/event-bus";
import moment from "moment";
import { checkValidationErrors, validateFields } from "@/utils/fns";
import { fillObjectForm } from "@/utils/component-fns";
import SelectMovement from "./SelectMovement";

export default {
  name: "AddNewPayment",
  props: [
    "expense",
    "income",
    "type",
    "accountType",
    "isAdvancedPayment",
    "objectInformation",
    "noInvoice",
  ],
  components: {},
  data() {
    return {
      // Table values
      newPayment: {
        CLAVE_FACTURA: undefined,
        CLAVE_FACTURA_GASTOS: undefined,
        CUENTA: "",
        CLAVE_PRV: "",
        CLAVE_CLIENTE: "",
        NO_CUENTA: "",
        NO_CLABE: "",
        NO_TARJETA: "",
        NO_CHEQUE: "",
        FAC_PRO: "",
        NO_DOCUM: "",
        PAGO_PARC: "",
        FECHA_PAGO: new Date(new Date().setHours(0, 0, 0, 0)),
        TIPO_PAGOP: "A",
        CLAVE_NOT: "",
        REFERENCIA: "",
        FORMA_P: "",
        TIPO_CH: "",
        STATUS: "AC",
        CLAVE_MOVIMIENTO: undefined,
        OBSERVACIONES: "",
        createMovement: false,
        affectBalance: false,
        requireCDFI: false,
      },
      CLIENTE_MERCADO_LIBRE: false,
      newPaymentErrors: {
        CUENTA: false,
        PAGO_PARC: false,
        FORMA_P: false,
        FECHA_PAGO: false,
        REFERENCIA: false,
      },
      selectedAccountBalance: 0,
      selectedAccountMovements: [],
      selectedAccountMovementBalance: 0,
      moment: moment,
      totalQuantity: 0,
      movementName: "",
      enableSeconds: true,
      hourFormat: "24",
      locale: "es-MX",
      dayNames: ["D", "L", "M", "M", "J", "V", "S"],
      monthNames: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
    };
  },
  async created() {
    moment.locale("es");
  },
  mounted() {
    if (!this.objectInformation) {
      console.log(this.income, this.accountType === "CC");
      if (this.accountType === "CP") {
        this.newPayment.CLAVE_OP = this.expense.NUM_FOLIO;
        this.newPayment.CLAVE_PRV = this.expense.CLAVE_PRV._id;
        this.newPayment.CLAVE_ALM = this.expense.CLAVE_ALMACEN._id;
        this.newPayment.FAC_PRO = this.expense.FACTURA;
        this.newPayment.GASTO_ID = this.expense._id;
        this.totalQuantity = this.expense.RESTA;
      } else {
        if (this.income.FACTURA_ID) {
          this.newPayment.CLAVE_FACTURA = [
            { FACTURA: this.income.FACTURA_ID, INGRESO: this.income._id },
          ];
        }
        this.newPayment.CLAVE_OP = this.income.NUM_FOLIO;
        this.newPayment.CLAVE_CLIENTE = this.income.CLAVE_CLIENTE._id;
        this.newPayment.CLAVE_ALM = this.income.CLAVE_ALMACEN._id;
        this.newPayment.NO_DOCUM = this.income.NO_DOCUM;
        this.newPayment.INGRESO_ID = this.income._id;
        this.totalQuantity = this.income.RESTA;
        this.CLIENTE_MERCADO_LIBRE =
          this.income.CLAVE_CLIENTE.CLIENTE_MERCADO_LIBRE;
        if (
          this.checkIfCanInvoiceCompany() &&
          this.accountType === "CC" &&
          this.type === "Venta" &&
          this.income &&
          ((this.income.CLAVE_VENTA &&
            this.income.CLAVE_VENTA.STATUS_FAC === "TI") ||
            (this.income.CLAVE_VENTA_SERVICIO &&
              this.income.CLAVE_VENTA_SERVICIO.STATUS_FAC)) &&
          this.income.CLAVE_VENTA.METODO_P === "D"
        ) {
          this.newPayment.requireCDFI = true;
        }
      }
      this.newPayment.TIPO_ING = this.type;
      this.newPayment.ES_ANTICIPO = this.isAdvancedPayment ? "S" : "N";
    }

    if (this.objectInformation) {
      // Fill the form with received information
      fillObjectForm(this.objectInformation, this.newPayment);
      this.newPayment.FORMA_P = this.objectInformation.FORMA_P._id;
      this.newPayment.FECHA_PAGO = new Date(this.objectInformation.FECHA_PAGO);

      if (this.objectInformation.CLAVE_MOVIMIENTO) {
        this.CLAVE_MOVIMIENTO = this.objectInformation.CLAVE_MOVIMIENTO._id;
        this.movementName = `${
          this.objectInformation.CLAVE_MOVIMIENTO.REFERENCIA
        } ${this.formatMoneyString(
          this.objectInformation.CLAVE_MOVIMIENTO.IMPORTE
        )}`;
      }
    }

    if (
      this.companyInformation.MOVIMIENTO_OBLIGATORIO &&
      !this.CLIENTE_MERCADO_LIBRE
    ) {
      this.newPaymentErrors.CLAVE_MOVIMIENTO = false;
    }

    EventBus.$on("searchMovementSelected", (selection) => {
      this.movementName = selection.movement.REFERENCIA;
      this.newPayment.CLAVE_MOVIMIENTO = selection.movement._id;
      this.handleMovementSelected();
    });
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    closeParentModal() {
      EventBus.$emit("closeIncomeModal");
      this.closeModal();
    },
    async addNewPayment() {
      try {
        if (!this.validateBankMovement()) {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: `Hubo un error al guardar el pago, la cantidad a registrar es mayor que el saldo disponible en el movimiento bancario seleccionado`,
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          return;
        }

        this.articleRequiredErrors = validateFields(
          this.newPayment,
          this.newPaymentErrors
        );

        if (checkValidationErrors(this.newPaymentErrors)) {
          if (this.accountType === "CP" && this.expenseNotValidQuantity()) {
            this.$buefy.dialog.confirm({
              title: "Error",
              message: `Hubo un error al guardar el pago, la cantidad a registrar es mayor que el restante a pagar de la cuenta por pagar, el restante es: $${this.expense.RESTA}`,
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
            return;
          }

          if (this.accountType === "CC" && this.incomeNotValidQuantity()) {
            this.$buefy.dialog.confirm({
              title: "Error",
              message: `Hubo un error al guardar el pago, la cantidad a registrar es mayor que el restante a pagar de la cuenta por cobrar, el restante es: $${this.income.RESTA}`,
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
            return;
          }

          if (this.newPayment.CLAVE_FACTURA) {
            for (const [
              singleInvoiceIndex,
              singleInvoice,
            ] of this.newPayment.CLAVE_FACTURA.entries()) {
              if (singleInvoice.FACTURA) {
                this.newPayment.CLAVE_FACTURA[singleInvoiceIndex].PAGO_PARC =
                  this.newPayment.PAGO_PARC;
              }
            }
          }

          let response = await this.$store.dispatch(
            this.accountType === "CP"
              ? "ADDPAYMENTEXPENSE"
              : "ADDPAYMENTINCOME",
            this.newPayment
          );

          if (response === "Success") {
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message: "Se guardó exitosamente el pago",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {
                this.closeModal();
              },
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message: "Hubo un error al guardar el pago en la base de datos",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al guardar el pago en la base de datos revise los campos Requeridos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        console.log(error);
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al guardar el pago la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    /**
     *
     * @desc sets the balance of the current selected bank account
     * @return { number } the balance on the selected bank account
     */
    setAccountInformation() {
      for (const singleBankAccount of this.bankAccounts) {
        if (this.newPayment.CUENTA === singleBankAccount._id) {
          this.selectedAccountBalance =
            singleBankAccount.SALDO_CON_ACTUAL.SALDO_ACTUAL;
          if (this.accountType === "CC") {
            this.selectedAccountMovements = singleBankAccount.MOVIMIENTOS.map(
              (singleMovement) => {
                const movementWithProps = { ...singleMovement };
                movementWithProps.MOVIMIENTO_NOMBRE_FOR = `${
                  singleMovement.REFERENCIA
                } ${this.formatMoneyString(singleMovement.IMPORTE)}`;
                return movementWithProps;
              }
            ).filter((singleMovement) => {
              if (
                singleMovement.STATUS === "CA" ||
                singleMovement.MOSTRAR_TESORERIA === "N"
              ) {
                return false;
              }

              if (
                !singleMovement.CLAVE_CLIENTE &&
                !singleMovement.CLAVE_PRV &&
                singleMovement.TIPOAFECTACION === "SU" &&
                singleMovement.RESTA > 1
              ) {
                return true;
              }

              if (
                singleMovement.CLAVE_CLIENTE &&
                singleMovement.CLAVE_CLIENTE._id ===
                  this.newPayment.CLAVE_CLIENTE &&
                singleMovement.TIPOAFECTACION === "SU" &&
                singleMovement.RESTA > 1
              ) {
                return true;
              }

              return false;
            });

            this.allSelectedAccountMovements = this.selectedAccountMovements;
          } else if (this.accountType === "CP") {
            this.selectedAccountMovements = singleBankAccount.MOVIMIENTOS.map(
              (singleMovement) => {
                const movementWithProps = { ...singleMovement };
                movementWithProps.MOVIMIENTO_NOMBRE_FOR = `${
                  singleMovement.REFERENCIA
                } ${this.formatMoneyString(singleMovement.IMPORTE)}`;
                return movementWithProps;
              }
            ).filter((singleMovement) => {
              if (
                singleMovement.STATUS === "CA" ||
                singleMovement.MOSTRAR_TESORERIA === "N"
              ) {
                return false;
              }

              if (
                !singleMovement.CLAVE_CLIENTE &&
                !singleMovement.CLAVE_PRV &&
                singleMovement.TIPOAFECTACION === "RE" &&
                singleMovement.RESTA > 1
              ) {
                return true;
              }

              if (
                singleMovement.CLAVE_PRV &&
                singleMovement.CLAVE_PRV._id === this.newPayment.CLAVE_PRV &&
                singleMovement.TIPOAFECTACION === "RE" &&
                singleMovement.RESTA > 1
              ) {
                return true;
              }

              return false;
            });

            this.allSelectedAccountMovements = this.selectedAccountMovements;
          }
          this.filterMovementsByCustomDateFilter();
        }
      }
    },
    filterMovementsByCustomDateFilter() {
      let filteredMovements = [];
      let accountDate =
        this.accountType === "CP"
          ? this.expense.FECHA_FAC
          : this.income.FECHA_DOC;

      for (const singleMovement of this.selectedAccountMovements) {
        if (
          singleMovement.FECHA &&
          moment(singleMovement.FECHA).format("L") ===
            moment(accountDate).format("L")
        ) {
          filteredMovements.push(singleMovement);
        }
      }

      for (let i = 1; i < 11; i += 1) {
        let upperDate = moment(accountDate).add(i, "days").format("L");
        let lowerDate = moment(accountDate).subtract(i, "days").format("L");

        for (const singleMovement of this.selectedAccountMovements) {
          if (
            singleMovement.FECHA &&
            moment(singleMovement.FECHA).format("L") === lowerDate
          ) {
            filteredMovements.push(singleMovement);
          }
        }

        for (const singleMovement of this.selectedAccountMovements) {
          if (
            singleMovement.FECHA &&
            moment(singleMovement.FECHA).format("L") === upperDate
          ) {
            filteredMovements.push(singleMovement);
          }
        }
      }

      this.selectedAccountMovements = filteredMovements;
    },
    async savePaymentInvoice() {
      try {
        let response = await this.$store.dispatch("SAVEINVOICEPAYMENT", {
          saleId: this.income.CLAVE_VENTA
            ? this.income.CLAVE_VENTA._id
            : this.income.CLAVE_VENTA_SERVICIO._id,
          paymentId: this.objectInformation._id,
        });
        if (response === "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: `Se generó el timbrado exitosamente`,
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            onConfirm: () => {
              this.closeParentModal();
            },
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: `Hubo un error al timbrar:  ${
              response ? JSON.stringify(response) : "Error en el servidor"
            }`,
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: `Hubo un error al timbrar en la base de datos: ${
            error ? JSON.stringify(error) : "Error en el servidor"
          }`,
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    expenseNotValidQuantity() {
      return this.expense.RESTA < this.newPayment.PAGO_PARC;
    },
    incomeNotValidQuantity() {
      return this.income.RESTA < this.newPayment.PAGO_PARC;
    },
    handleMovementSelected() {
      if (this.newPayment.CLAVE_MOVIMIENTO) {
        this.newPayment.affectBalance = false;
        this.newPayment.createMovement = false;

        for (const singleMovement of this.allSelectedAccountMovements) {
          if (singleMovement._id === this.newPayment.CLAVE_MOVIMIENTO) {
            this.selectedAccountMovementBalance = singleMovement.RESTA;
          }
        }
      }
    },
    validateBankMovement() {
      if (this.newPayment.CLAVE_MOVIMIENTO) {
        return this.selectedAccountMovementBalance >= this.newPayment.PAGO_PARC;
      }
      return true;
    },
    autoCompleteMovement(value) {
      if (!this.selectedAccountMovements) {
        return [];
      }
      return this.selectedAccountMovements.filter((singleMovement) => {
        return (
          singleMovement.IMPORTE &&
          singleMovement.IMPORTE.toString()
            .toLowerCase()
            .indexOf(value.toLowerCase()) >= 0
        );
      });
    },
    formatMoneyString(val) {
      return new Intl.NumberFormat("es-MX", {
        style: "currency",
        currency: "MXN",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(parseFloat(val));
    },
    openSearchMovement() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: SelectMovement,
        props: {
          accountType: this.accountType,
          bankAccount: this.newPayment.CUENTA,
          paymentInformation: this.newPayment,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
  },
  computed: {
    warehouses() {
      return this.$store.getters.WAREHOUSES;
    },
    providers() {
      return this.$store.getters.PROVIDERS;
    },
    lastPaymentNumber() {
      return this.$store.getters.LASTPAYMENTNUMBER;
    },
    bankAccounts() {
      return this.$store.getters.BANKACCOUNTS;
    },
    paymentTypes() {
      return this.$store.getters.PAYMENTTYPES;
    },
    companyInformation() {
      return this.$store.getters.COMPANY_INFORMATION;
    },
  },
  watch: {
    companyInformation() {
      if (
        this.companyInformation.MOVIMIENTO_OBLIGATORIO &&
        !this.CLIENTE_MERCADO_LIBRE
      ) {
        this.newPaymentErrors.CLAVE_MOVIMIENTO = false;
      }
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
@import "../../Purchases/style/AddPurchaseOrder.css";
@import "../../Configuration/style/Configuration.css";
</style>
